import React, { useState, useEffect } from "react";
import "./clients.css";
import {
  wv,
  aquaya,
  ads,
  cms,
  kianda,
  kiss,
  nor,
  pwc,
  tanari,
  uu,
  treasury,
  jawabu,
} from "./images/img";

const items = [
  { img: nor },
  { img: aquaya },
  { img: cms },
  { img: pwc },
  { img: treasury },
  { img: ads },
  { img: wv },
  { img: uu },
  { img: kiss },
  { img: kianda },
  { img: tanari },
  { img: jawabu },
];

const Card = (props) => {
  return (
    <li className="card">
      <img
        className="material-icons"
        width="375px"
        height="275px"
        src={props.img}
        alt=""
      />
    </li>
  );
};

const Clients = () => {
  const [carouselItems, setCarouselItems] = useState(items);

  useEffect(() => {
    const interval = setInterval(() => {
      shiftNext([...carouselItems]);
    }, 3000); // Change the duration as needed
    return () => clearInterval(interval);
  }, [carouselItems]);

  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setCarouselItems(copy);
  };

  return (
    <div className="carouselwrapper module-wrapper" id="clients">
      <div className="clients-content">
      <h1 className="d-flex justify-content-center pb-2 pt-2 clients-title">
        OUR CLIENTS
      </h1>
      <hr />
      </div>
      <ul className="carousel">
        {carouselItems.map((t, index) => (
          <Card key={index} img={t.img} />
        ))}
      </ul>
    </div>
  );
};

export default Clients;
