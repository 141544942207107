import React from 'react';
import { in180, beta1, short, todad1, solda, faiv2 } from './images/img';
import './projects.css';

const Projects = () => {
  return (
    <>
      <section className='d-flex flex-column pr'>
        <h2 className='projects-title'>PROJECTS</h2>
        <div className="projects-container1">
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${beta1})` }} />
            <div className="project-details1">
              <h2><strong>#4Better4Worse</strong></h2>
              <p>Join us as we come back with a new season full of drama and insightful conversation to enrich marriages. A new episode drops every week.</p>
              <button><a href="https://www.youtube.com/watch?v=o9YSMV4b5tA&list=PLldYQNafrxy8LAaEkM9zF46AOCK8Qh-Xw&ab_channel=PichaSafiProductionsKE" target="_blank" rel="noreferrer">See Project</a></button>
            </div>
          </div>
          <hr/>
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${todad1})` }} />
            <div className="project-details1">
              <h2><strong>Letters to Dad</strong></h2>
              <p>Join us onFather’s Day every year, as we screen a documentary dubbed “Letters to Dad’. This is a documentary that focuses on fatherhood in the aim of spurring conversation around the role and importance of fathers in the family, the church & the Society.</p>
            </div>
          </div>
          <hr/>
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${faiv2})` }} />
            <div className="project-details1">
              <h2><strong>Five4One</strong></h2>
              <p>Join us as we premiere 5 amazing short Films in an unforgettable evening full of pomp and color for just one ticket.</p>
            </div>
          </div>
          <hr/>
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${in180})` }} />
            <div className="project-details1">
              <h2><strong>IN180</strong></h2>
              <p>Short stories about ordinary people living life the best way they can</p>
              <button><a href="https://www.youtube.com/playlist?list=PLldYQNafrxy_Ah9xv0F_Lyx7H5NCe7IUQ" target="_blank" rel="noreferrer">See Project</a></button>
            </div>
          </div>
          <hr/>
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${short})` }} />
            <div className="project-details1">
              <h2><strong>Short Online Films</strong></h2>
              <p>Watch hilarious, thrilling, emotional yet informative short films dropping regularly on our YouTube channel.
              </p>
              <button>In Production</button>
            </div>
          </div>
          <hr/>
          <div className='d-flex project1'>
            <div className="back1" style={{ backgroundImage: `url(${solda})` }} />
            <div className="project-details1">
              <h2><strong>WOUNDED SOLDIER</strong></h2>
              <p>Documentary that will be looking at Church hurt and why it runs so deep</p>
            </div>
          </div>
          <hr/>
        </div>
      </section>
    </>
  )
}

export default Projects;