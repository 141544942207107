import React from "react";
import { BsFacebook, BsYoutube, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { logo } from "./images/img";
import "./footer.css";
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="pg-footer">
      <footer className="footer">
        <svg
          className="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div className="footer-content">
          <div className="footer-content-column">
            <div className="footer-logo">
              <a className="footer-logo-link" href="#home">
                <h2>PichaSafi</h2>
              </a>
            </div>
            <div className="footer-menu">
              <img
                className="bg-dark rounded-circle"
                src={logo}
                width="150"
                height="150"
                alt="psp logo"
              ></img>
            </div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> Company</h2>
              <ul id="menu-company" className="footer-menu-list">
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="#contacts">Contact</a>
                </li>
                <li className="menu-item menu-item-type-taxonomy menu-item-object-category">
                  <a href="#about">News</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="#projects">Careers</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> Quick Links</h2>
              <ul id="menu-quick-links" className="footer-menu-list">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <a href="#about-us">
                    About
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="#work">Our Work</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="#clients">Clients</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="projects">Projects</a>
                </li>
                <li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                  <a href="#contacts">Contacts</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content-column">
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title"> Let's Chat</h2>
              <p className="footer-call-to-action-description">
                {" "}
                Do you have an enquiry? We will get back to you with the information you need.
              </p>
              <a
                className="footer-call-to-action-button button"
                href="mailto:productions@pichasafi.ke"
                target="_self"
              >
                {" "}
                Get in Touch{" "}
              </a>
            </div>
            <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title"> WhatsApp Text Us on</h2>
              <p className="footer-call-to-action-link-wrapper">
                {" "}
                <a
                  className="footer-call-to-action-link"
                  href="tel:+254 720 924 939"
                  target="_self"
                >
                  {" "}
                  +254 720 924 939{" "}
                </a>
              </p>
            </div>
          </div>
          <div className="footer-social-links">
  <svg
    className="footer-social-amoeba-svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 236 54"
  >
    <path
      className="footer-social-amoeba-path"
      d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"
    ></path>
  </svg>
  <a
    className="footer-social-link youtube"
    href="https://www.youtube.com/c/PichaSafiProductionsKE"
    target="_blank"
    rel="noreferrer"
  >
    <BsYoutube />
  </a>
  <a
    className="footer-social-link twitter"
    href="https://twitter.com/pichasafike"
    target="_blank"
    rel="noreferrer"
  >
    <BsTwitter />
  </a>
  <a
    className="footer-social-link facebook"
    href="https://facebook.com/pichasafiproductionsKE"
    target="_blank"
    rel="noreferrer"
  >
    <BsFacebook />
  </a>
  <a
    className="footer-social-link instagram"
    href="https://instagram.com/pichasafiproductionsKE"
    target="_blank"
    rel="noreferrer"
  >
    <BsInstagram />
  </a>
  <a
    className="footer-social-link linkedin"
    href="https://www.linkedin.com/company/pichasafiproductionske"
    target="_blank"
    rel="noreferrer"
  >
    <BsLinkedin />
  </a>
  <a
    className="footer-social-link tiktok"
    href="https://www.tiktok.com/@pichasafiproductionske"
    target="_blank"
    rel="noreferrer"
  >
    <FaTiktok />
  </a>
</div>
        </div>
        <div className="footer-copyright">
            <span className="footer-copyright-text">
              <a className="footer-copyright-link" href="#home" target="_self">
                {" "}
                ©{currentYear}. | PichaSafiProductionsKE. | All rights reserved.
                </a>
            </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
