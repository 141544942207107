// Updated GoalSection component JSX

import React from 'react';
import './goals.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faEye, faCheckCircle, faUsers } from '@fortawesome/free-solid-svg-icons'; // Import appropriate icons

function GoalSection() {
  return (
    <div className="goal-section">
        <div className="goals">
        <FontAwesomeIcon icon={faCheckCircle} className="icon" />
          <h2>
            Our Goal
          </h2>
          <p>
            Our goal is to create content that inspires positive change in the community. Our business focuses on three areas:
          </p>
          <ul>
            <li>Capacity</li>
            <li>Content</li>
            <li>Circulation</li>
          </ul>
        </div>
        <hr />
        <div className="core-values">
        <FontAwesomeIcon icon={faUsers} className="icon" />
          <h2>
            Core Values
          </h2>
          <ul>
            <li>Consistency</li>
            <li>Courage</li>
            <li>Credibility</li>
            <li>Collaboration</li>
          </ul>
        </div> 
        <hr />     
        <div className="mission">
          <FontAwesomeIcon icon={faBullseye} className="icon" />
          <h2>Mission</h2>
          <p>To create and enable the creation of content that informs, entertains, and educates to inspire social values in the society. </p>
        </div>
        <hr />
        <div className="vision">
          <FontAwesomeIcon icon={faEye} className="icon" />
          <h2>Vision</h2>
          <p>The leading infotainment content producer in Kenya by 2030.</p>
        </div>
        <hr />
    </div>
  );
}

export default GoalSection;
