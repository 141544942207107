import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import "./contact.css";

const Contacts = () => {
  return (
    <section>
      <div id="contacts">
        <div className="section-header">
          <div className="container">
            <div className="contacts-content">
            <h2>CONTACT US</h2>
            <hr />
            </div>
            <p>
            Content creation is our business, yours is to enjoy Return on Your Investment. Contact us to help you create capacity in your team for content creation, partner with you to create content and help you get it to the right audience soliciting the desired response and action.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                <FontAwesomeIcon icon={faHome} />
                 </div>
                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                  Ukumbusho House,
                  <br /> ACK Emmanuel Church, Kibichiku <br />
                  Kibichiku Road, off Kitisuru – Wangige Road                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                <FontAwesomeIcon icon={faPhone} />
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>+254 720 924 939</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                <FontAwesomeIcon icon={faEnvelope} />
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>productions@pichasafi.co.ke</p>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form
                action="https://formsubmit.co/f258b240853426b0e57b62099afdddae"
                method="POST"
                id="contact-form"
              >
                <h2>Send Message</h2>
                <div className="input-box">
                  <input type="text" required name="" />
                  <span>Full Name</span>
                </div>

                <div className="input-box">
                  <input type="email" required name="" />
                  <span>Email</span>
                </div>

                <div className="input-box">
                  <textarea required name=""></textarea>
                  <span>Type your Message...</span>
                </div>
                <div>
                  <input type="hidden" name="_template" value="table" />
                  <input type="hidden" name="_captcha" value="false" />
                  <input
                    type="hidden"
                    name="_next"
                    value="https://pichasafi.co.ke/"
                  />
                </div>
                <div className="input-box">
                  <input type="submit" value="Send" name="" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
