import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { logo } from './images/img';
import './App.css';

const Navigation = () => {
  const location = useLocation();
  const isProjectsPage = location.pathname === '/projects';
  const isPortfolioPage = location.pathname === '/portfolio';
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const handleToggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <>
      {['md'].map((expand) => (
        <Navbar
          key={expand}
          bg="dark"
          variant="dark"
          expand="lg"
          fixed="top"
          className={'position-sticky ps-0'}
        >
          <Container fluid>
            <Navbar.Brand className="p-0 ps-5 d-flex justify-content-center align-items-center" href="#">
              <img className="bg-dark" src={logo} width="60" height="60" alt="psp logo" />
              <h5 className="text h5"><strong>PICHA SAFI PRODUCTIONS</strong></h5>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleToggleOffcanvas} />
            <Navbar.Offcanvas
              show={showOffcanvas}
              onHide={handleCloseOffcanvas}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="offcanvas-custom" // added custom class
            >
              <Offcanvas.Header closeButton variant="light" className={'bg-dark text-light'}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className={'bg-light'}>
                  <img src={logo} width="60" height="60" alt="psp logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-flex justify-content-center align-items-center">
                <Nav className="d-flex justify-content-end align-items-center flex-grow-1 pe-3 navtext">
                  {/* Conditionally render navigation items */}
                  {!isProjectsPage && !isPortfolioPage && (
                    <>
                      <Nav.Link className="text" href="#about-us" onClick={handleCloseOffcanvas}>ABOUT</Nav.Link>
                      <Nav.Link className="text" href="#work" onClick={handleCloseOffcanvas}>WORK</Nav.Link>
                      <Nav.Link className="text" href="#projects" onClick={handleCloseOffcanvas}>PROJECTS</Nav.Link>
                      <Nav.Link className="text" href="#clients" onClick={handleCloseOffcanvas}>CLIENTS</Nav.Link>
                      <NavLink className="text" to="/portfolio" onClick={handleCloseOffcanvas}>PORTFOLIO</NavLink> 
                      <Nav.Link className="text" href="#contacts" onClick={handleCloseOffcanvas}>CONTACTS</Nav.Link>
                    </>
                  )}
                  {isProjectsPage && (
                    <>
                      <NavLink className="d-flex align-items-center text p-2" to="/" onClick={handleCloseOffcanvas}>HOME</NavLink>
                      <NavLink className="d-flex align-items-center text p-2" to="/portfolio" onClick={handleCloseOffcanvas}>PORTFOLIO</NavLink>
                    </>
                  )}
                  {isPortfolioPage && (
                    <>
                      <NavLink className="d-flex align-items-center text p-2" to="/" onClick={handleCloseOffcanvas}>HOME</NavLink>
                      <NavLink className="d-flex align-items-center text p-2" to="/projects" onClick={handleCloseOffcanvas}>PROJECTS</NavLink>
                    </>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Navigation;
