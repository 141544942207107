import React, { useState } from 'react';
import "./portfolio.css";
import images from './galleryimages/galleryimages'; // Import the statically loaded images

const Portfolio = () => {
  const [visibleImages, setVisibleImages] = useState(15);

  const loadMoreImages = () => {
    setVisibleImages(prevVisibleImages => prevVisibleImages + 15);
  };

  return (
    <section className="gallery">
      <div className="gallery-grid">
        {images.slice(0, visibleImages).map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image} alt={`Portfolio item ${index + 1}`} className="gallery-image" />
          </div>
        ))}
      </div>
      {visibleImages < images.length && (
        <button className="load-more" onClick={loadMoreImages}>
          Load More
        </button>
      )}
    </section>
  );
};

export default Portfolio;
