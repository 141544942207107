import React, { useEffect, useState, useMemo } from "react";
import { beta, todad, faiv1 } from './images/img';

const ProjectComponent = () => {
  // Define premiere dates and descriptions for each project
  const projectsData = useMemo(() => [
    {
      title: "Letters To Dad",
      premiereDate: new Date("2025-06-15T00:00:00"),
      description: "Join us on Father’s Day every year, as we screen a documentary dubbed “Letters to Dad’. This is a documentary that focuses on fatherhood in the aim of spurring conversation around the role and importance of fathers in the family, the church & the Society."
    },
    {
      title: "Five4One",
      premiereDate: new Date("2024-11-14T00:00:00"),
      description: "Join us as we premiere 5 amazing short Films in an unforgettable evening full of pomp and color for just one ticket."
    },
    {
      title: "#4Better4Worse",
      premiereDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
      description: "Join us as we come back with a new season full of drama and insightful conversation to enrich marriages. A new episode drops every week."
    }
  ], []); // Empty dependency array means `projectsData` is only created once

  // Calculate remaining time until premiere for each project
  const calculateTimeRemaining = (premiereDate) => {
    const now = new Date();
    const timeRemaining = premiereDate - now;
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  const [countdowns, setCountdowns] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedCountdowns = {};
      projectsData.forEach(({ title, premiereDate }) => {
        updatedCountdowns[title] = calculateTimeRemaining(premiereDate);
      });
      setCountdowns(updatedCountdowns);
    }, 1000);
    return () => clearInterval(interval);
  }, [projectsData]);

  // Function to truncate description to a specified number of words
  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : description;
  };

  return (
    <>
      <section className="projects-container" id="projects">
        <div className="projects-content">
          <h2 className="projects-title">PROJECTS</h2>
          <hr />
        </div>
        <div className="projects-grid">
          {projectsData.map(({ title, premiereDate, description }) => (
            <div className='project' key={title}>
              <div className="back" style={{backgroundImage: `url(${
                title === "Letters To Dad" ? todad :
                title === "Five4One" ? faiv1 :
                beta
              })` }}>
                <div className='project-details'>
                  <h2><strong>{title}</strong></h2>
                  <p>
                    {truncateDescription(description, 10)} {/* Limit description to 20 words */}
                  </p>                  
                  <div className="details-row">
                    <div className="details-column">
                      <p>Countdown to:</p>
                      <p>{premiereDate.toDateString()}</p>
                    </div>
                    <div className="details-column1">
                      <div className="countdown-item">
                        <p>{countdowns[title] && countdowns[title].days}</p>
                        <span>Days</span>
                      </div>
                      <div className="countdown-item">
                        <p>{countdowns[title] && countdowns[title].hours}</p>
                        <span>Hours</span>
                      </div>
                      <div className="countdown-item">
                        <p>{countdowns[title] && countdowns[title].minutes}</p>
                        <span>Minutes</span>
                      </div>
                      <div className="countdown-item">
                        <p>{countdowns[title] && countdowns[title].seconds}</p>
                        <span>Seconds</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <a href="projects" className="projects-call-to-action">See more</a>
      </section>
    </>
  );
};

export default ProjectComponent;
