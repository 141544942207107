const logo = require('./psp-logo.png')
const aquaya = require('./aquaya.webp')
const wv = require('./WV.png')
const ads = require('./hsf.jpeg')
const cms = require('./cms.png')
const kianda = require('./kianda.png')
const kiss = require('./kiss.jpg')
const medeva = require('./medeva.png')
const metropol = require('./metropol.jpg')
const nor = require('./norchurch.png')
const pwc = require('./pwc.png')
const tanari = require('./tanari.jpg')
const uu = require('./uungwana.jpg')
const treasury = require('./treasury.webp')
const in180 = require('./in180.jpg')
const beta = require('./IMG-20240904-WA0007.jpg')
const short = require('./4better.jpg')
const solda = require('./soldier.JPG')
const todad = require('../galleryimages/Derrick-Milimo-Documentary-photographer-9293.jpg')
const todad1 = require('../galleryimages/Derrick-Milimo-Documentary-photographer-9350.jpg')
const faiv = require('./5-4-1.jpg')
const jawabu = require('./jawabu.png')
const faiv1 = require('../galleryimages/IMG-20240116-WA0025.jpg')
const faiv2 = require('../galleryimages/IMG-20240117-WA0159.jpg')
const beta1 = require('../galleryimages/Derrick-Milimo-Documentary-photographer-6080.jpg')

export { logo, aquaya,  wv, ads, cms, kianda, kiss, medeva, metropol, nor, pwc, tanari, uu, treasury, in180, beta, short,todad,todad1,solda, faiv, jawabu, faiv1, faiv2, beta1 };
