import { Routes, Route } from "react-router-dom";
import Navigation from "./Nav";
import Home from "./Home";
import Projects from "./Projects";
import Footer from "./Footer";
import Portfolio from "./Portfolio";

function App() {
  return (
    <>
      <Navigation />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
