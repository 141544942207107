import React from "react";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube, BsLinkedin } from "react-icons/bs";
import { FaTiktok } from 'react-icons/fa';
import "./about.css"; // Import the CSS file for styling

function About() {
  return (
    <div className="about-container" id="about-us">
      <div className="about-content">
        <h1>PICHA SAFI PRODUCTIONS</h1>
        <p className="about-text">
        Picha Safi Productions is a content creation service company based in Nairobi, operating across Kenya and East Africa. <br /> We pride ourselves in creating and facilitating the creation of content that promotes social values in order to drive behavior change.
        </p>
        <a href="mailto:productions@pichasafi.ke" className="contact-button">Get in touch</a>
      </div>
      <div className="vid">
        <iframe
          className="video"
          src="https://www.youtube.com/embed/2pMO0UNGiqs?autoplay=1&&mute=1&playlist=2pMO0UNGiqs&loop=1&controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
      <div className="about-social-links">
        <ul className="list-unstyled d-flex flex-column ps-1 position-fixed" style={{ paddingTop: '20px' }}>
          <li id="facebook" className="mb-3">
            <a
              href="https://facebook.com/pichasafiproductionsKE"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook />
            </a>
          </li>
          <li className="mb-3">
            <a
              href="https://instagram.com/pichasafiproductionsKE"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </li>
          <li className="mb-3">
            <a
              href="https://twitter.com/pichasafike"
              target="_blank"
              rel="noreferrer"
            >
              <BsTwitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/c/PichaSafiProductionsKE"
              target="_blank"
              rel="noreferrer"
            >
              <BsYoutube />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/picha-safi-productions/"
              target="_blank"
              rel="noreferrer"
            >
              <BsLinkedin />
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@pichasafiproductionske"
              target="_blank"
              rel="noreferrer"
            >
              <FaTiktok />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default About;
