import React from "react";
import "./works.css";

const Work = () => {
  return (
    <div className="services-container"id="work">
      <div className="services-content">
        <h2>OUR SERVICES</h2>
        <hr />
      </div>
      <div className="service">
        <h3>Documentary Production</h3>
        <p>We have over 15 years of experience in documentary production. Armed with the latest array of equipment, and a skilled professional team, we will understand your needs and deliver a tailor-made solution for your audience at the most competitive budget. We will deliver within agreed timelines and budget.</p>
      </div>
      <div className="service">
        <h3>Film & Television Production</h3>
        <p>We offer you solutions that will help you bring that film or TV show to life. We will guide you on the legal requirements, crew selection, casting, location scouting, equipment hire negotiation, production and distribution.</p>
      </div>
      <div className="service">
        <h3>News Feature Production</h3>
        <p>As a broadcast house at times, you may have your in-house team swamped and would require an outside team to lend your news team production support. Armed with years of working in a newsroom environment, will work within your editorial policy to bring you the best within the timelines and budgets agreed. We promise a quick turnaround time.</p>
      </div>
      <div className="service">
        <h3>Script Research & Development</h3>
        <p>We will help you bring your thoughts to life through professional scripting from idea to final draft.</p>
      </div>
      <div className="service">
        <h3>Postproduction Services</h3>
        <p>We will help you get the best terms as you start your postproduction journey. We will also manage the process to ensure it runs smoothly and within schedule.</p>
      </div>
      <div className="service">
        <h3>Digital Video and Photography</h3>
        <p>Running an SME needs constant content to keep your customers engaged. We will work with your in-house team to develop a strategy, train them to produce the best and grow in capacity to do the work in-house.</p>
      </div>
      <div className="service">
        <h3>Production Management Services</h3>
        <p>Are you planning a production, avoid the stress of running around and concentrate on creating. Let us take that load off you. We have the team and skills to manage your production so that you can concentrate on what matters the most.</p>
      </div>
      <div className="service">
        <h3>Marketing & Publicity of Content</h3>
        <p>Your brilliant idea turned into film needs to reach the intended market with the desired impact. We have the team that will guide you to develop a marketing strategy and manage the marketing process to enable you to realize your objectives.</p>
      </div>
    </div>
  );
};

export default Work;
