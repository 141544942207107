import React from "react";
import Clients from "./Clients";
import Contacts from "./Contacts";
import Work from "./Our Work";
import About from "./About";
import ProjectComponent from "./projectComponent";
import GoalSection from "./Goals";

const Home = () => {
  return (
    <>
      <About />
      <GoalSection />
      <Work />
      <ProjectComponent />
      <Clients />
      <Contacts />
    </>
  );
};

export default Home;
